import { defineStore } from 'pinia';
import { User, Claim } from './User';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { useContainer } from '@/plugins/inversify';
import { MenuPageLink } from '@geta/kolumbus-frontend/composables';
import { computed, reactive, toRefs } from 'vue';
import { UserInfo } from '@geta/kolumbus-frontend/models';
import { GetProfileResponseDto } from '@/types/webapi';

const initialUser: User = { isLoggedIn: false, name: '' };
const initialProfile: UserInfo = { displayName: '' };
const initialClaims: Claim[] = [];

const useUserStore = defineStore('userStore', () => {
    const container = useContainer();
    const state = reactive({
        user: initialUser,
        claims: initialClaims,
        profile: initialProfile
    });

    const isLoggedIn = computed(() => state.user.isLoggedIn);
    const getProfile = computed(() => state.profile);

    function getClaim(name: string) {
        return state.claims.find(x => x.type == name)!.value;
    }

    async function loadUser() {
        const axios = container.get<AxiosInstance>(IAxiosId);

        const { data, status } = await axios.get<Claim[]>('/bff/user', {
            headers: {
                'X-CSRF': '1'
            },
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status == 401; // allow 401 - silent-login will handle it
            }
        });

        if (status != 200) {
            return;
        }

        state.claims = data;
        state.user = {
            isLoggedIn: true,
            name: getClaim('name')
        };

        state.profile.displayName = state.user.name;
    }

    async function loadProfile(profilePage?: Record<string, MenuPageLink>) {
        if (!state.user.isLoggedIn) return;

        const axios = container.get<AxiosInstance>(IAxiosId);
        const { data, status } = await axios.get<GetProfileResponseDto>('/api/v1.0/profile', {
            headers: {
                'X-CSRF': '1'
            },
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status == 404; // allow 404 - profile not created
            }
        });

        if (status != 200) {
            return;
        }

        state.profile.displayName = `${data.firstName} ${data.lastName}`;

        if (profilePage) {
            state.profile.profilePage = profilePage;
        }
    }

    return {
        ...toRefs(state),
        isLoggedIn,
        getProfile,
        loadUser,
        loadProfile
    };
});

export default useUserStore;
